import { take } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTags } from "../../shared/services/tag/tag.service";

const FindYourInspiration = () => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
        getTags().then((resp) => {
            setTags(take(resp.response, 25));
        });
    }, []);
    return (
        <div>
            <div className="flex gap-10 items-center">
                <div className="hidden flex-1 md:flex gap-6 text-5xl text-primary-white items-center font-bold tracking-wide">
                    <div>Find your</div>
                    <div className="space-y-2">
                        <div className="opacity-10">strength.</div>
                        <div className="opacity-30">motivation.</div>
                        <div className="text-primary-red">inspiration.</div>
                        <div className="opacity-30">courage.</div>
                        <div className="opacity-10">passion.</div>
                    </div>
                </div>
                <div className="flex-1 ">
                    <div className="text-primary-white space-y-4">
                        <div className="text-2xl md:text-3xl">
                            What moves you? What are you struggling with?
                        </div>
                        <div className="font-light">
                            We’ve captured hundreds of stories of brave, kind
                            and determined people. Just like you.
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {tags.map((tag: any) => {
                                return (
                                    <Link
                                        to={"/search?tag=" + tag.name}
                                        key={tag.id}
                                        className="tag-button small"
                                    >
                                        {tag.name}
                                    </Link>
                                );
                            })}
                            <Link
                                to={"/search"}
                                className="primary-button small"
                            >
                                so much more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FindYourInspiration;
