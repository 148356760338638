import { uniqBy } from "lodash";
import React, { useEffect, useRef } from "react";
import { useRouteMatch } from "react-router";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { updateLastWatched } from "../helpers/playback";
import "./VideoJS.less";

const VideoJS = (props: any) => {
    const { params } = useRouteMatch<{ id: string }>();

    const videoRef = useRef<any>(null);
    const playerRef = useRef<any>(null);
    const { options, isAudio, onReady, setClosedAt } = props;

    useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = (playerRef.current = videojs(
                videoElement,
                options,
                () => {
                    console.log("player is ready");
                    onReady && onReady(player);
                }
            ));

            player.on("ended", () => {
                console.log("ENDED...");
                updateLastWatched(params.id, 100);
                setClosedAt(() => {
                    return 100;
                });
            });

            player.on("loadedmetadata", () => {
                const lastWatched = JSON.parse(
                    localStorage.getItem("LastWatched") || "[]"
                );
                const percent =
                    lastWatched.find((x: any) => x.id === params.id)?.watched ||
                    0;
                const currentTime = (percent / 100) * player.duration();
                player.currentTime(currentTime);
            });
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const interval = setInterval(() => {
            setClosedAt(() => {
                const duration = (
                    (playerRef.current.currentTime() /
                        playerRef.current.duration()) *
                    100
                ).toFixed();
                updateLastWatched(params.id, duration);
                return duration;
            });
        }, 5000);

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
                clearInterval(interval);
                console.log("This is end");
            }
        };
    }, []);

    return (
        <div data-vjs-player data-is-audio={isAudio}>
            <video
                ref={videoRef}
                className={`video-js vjs-big-play-centered `}
            ></video>
        </div>
    );
};

export default VideoJS;
