import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import {
    getContentById,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import styles from "./index.module.less";
import Related from "./Related";
import Watch from "./Watch";
import ContentActions from "./ContentActions";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Footer from "../../shared/components/Footer";
import { Link } from "react-router-dom";
import { ReactComponent as ClockIcon } from "./../../../assets/images/clock.svg";
import { ReactComponent as PlayIcon } from "./../../../assets/images/play.svg";


const Content = () => {
    const [content, setContent] = useState<any>(null);

    const { params } = useRouteMatch<{ id: string; state?: string }>();

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (params.id) {
            getContentById(params.id)
                .then((resp) => {
                    setContent(mapContentWithLength([resp.response])[0]);
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [params]);

    return (
        <div className="md:absolute left-44 right-12 flex flex-col min-h-screen-stiry px-6 md:px-0 md:pt-8">
            {content && (
                <div className={"space-y-6 mb-10"}>
                    {params.state === "watch" ? (
                        <Watch content={content} />
                    ) : (
                        <>
                            <div
                                className={`aspect-w-5 md:aspect-h-2 aspect-h-3 bg-center bg-no-repeat bg-cover  `}
                                style={{
                                    backgroundImage: `url(${
                                        content.media.type.toLowerCase() ===
                                        "image"
                                            ? content.media.url
                                            : content.cover.url
                                    })`,
                                }}
                            >
                                <div
                                    className={`p-6  shadow-2xl flex flex-col justify-between ${styles.coverOverlay} `}
                                >
                                    <Link
                                        to={`/content/${content.id}/watch`}
                                        className="flex-grow md:space-y-4 space-y-2"
                                    >
                                        <div
                                            className={`text-primary-white font-bold text-2xl md:text-4xl `}
                                        >
                                            {content.title}
                                        </div>
                                        <div className={`text-primary-white `}>
                                            {content.subtitle}
                                        </div>
                                        <div
                                            className={`text-primary-white flex gap-2 items-center`}
                                        >
                                            <ClockIcon />

                                            {content.duration}
                                        </div>
                                        <div
                                            className={`flex flex-col justify-between ${styles.playButtonClass}`}
                                        >
                                            <PlayIcon />

                                        </div>
                                        
                                    </Link>
                                    <ContentActions content={content} />
                                </div>
                            </div>

                            {content.description ? (
                                <div>
                                    <div
                                        className={`whitespace-pre-wrap ${
                                            !expanded
                                                ? "line-clamp-4"
                                                : "line-clamp-none"
                                        }`}
                                    >
                                        {content.description}
                                    </div>
                                    <div className="flex justify-end">
                                        <div
                                            onClick={() =>
                                                setExpanded(!expanded)
                                            }
                                            className="cursor-pointer"
                                        >
                                            {expanded ? (
                                                <UpOutlined className="text-xl" />
                                            ) : (
                                                <DownOutlined className="text-xl" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )}
                    <Related content={content} />
                </div>
            )}

            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default Content;
