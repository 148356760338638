import React, { useEffect, useState } from "react";
import { Button } from "antd";
import CheckoutModal from "../../shared/components/CheckoutModal";
import {
    createSubscription,
    getStripeProducts,
} from "../../shared/services/stripe/stripe.service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Footer from "../../shared/components/Footer";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE));

const Subscription = () => {
    const history = useHistory();

    const [products, setProducts] = useState([]);

    const [showCheckoutModal, setShowCheckoutModal] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState({
        product: {
            id: "",
        },
        price: {
            id: "",
        },
    });

    useEffect(() => {
        getStripeProducts().then((resp) => {
            setProducts(resp.response);
        });
    }, []);

    return (
        <Elements stripe={stripePromise}>
            <div className="absolute md:left-44 right-12 pt-10  flex flex-col min-h-screen-stiry">
                <div className="flex justify-between">
                    <div
                        className={`text-3xl text-primary-white font-bold mb-10`}
                    >
                        Subscription
                    </div>
                </div>

                <div className="grid justify-center grid-cols-3 gap-10 mb-10">
                    {products.map((product: any) => {
                        return (
                            <div
                                key={product.product.id}
                                className="aspect-w-3 aspect-h-4 bg-tertiary-dark rounded-lg"
                            >
                                <div className="grid place-items-center text-center px-4">
                                    <div>
                                        <div className="flex justify-center mb-4 flex-col gap-4">
                                            <div className="text-2xl">
                                                {product.product.name}
                                            </div>
                                            <div>
                                                {product.product.description}
                                            </div>

                                            {product.prices.map(
                                                (price: any) => {
                                                    return (
                                                        <div
                                                            key={price.id}
                                                            className="space-y-4"
                                                        >
                                                            <div>
                                                                $
                                                                {price.unit_amount_decimal /
                                                                    100}
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    type={
                                                                        "primary"
                                                                    }
                                                                    size={
                                                                        "large"
                                                                    }
                                                                    onClick={() => {
                                                                        setSelectedProduct(
                                                                            {
                                                                                product:
                                                                                    product.product,
                                                                                price,
                                                                            }
                                                                        );

                                                                        setShowCheckoutModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Select
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex-grow flex flex-col justify-end">
                    <Footer />
                </div>
                <CheckoutModal
                    showModal={showCheckoutModal}
                    onClose={() => setShowCheckoutModal(false)}
                    onSuccess={(paymentMethodId: string) => {
                        createSubscription({
                            paymentMethodId,
                            productId: selectedProduct.product.id,
                            priceId: selectedProduct.price.id,
                        }).then(() => {
                            toast.success(
                                "You are now successfully subscribed."
                            );
                            setShowCheckoutModal(false);
                            history.push("/");
                        });
                    }}
                />
            </div>
        </Elements>
    );
};

export default Subscription;
