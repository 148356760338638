import React from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { changePasswordRequest } from "../../shared/services/auth/auth.service";
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const onFinish = (values: any) => {
        changePasswordRequest(values).then((resp) => {
            toast.success(
                "Password reset requested successfully. Please check you email to reset your password."
            );
        });
    };

    return (
        <Form
            name="ForgotPassword"
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: "Please input your Email!" },
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Reset Password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ForgotPassword;
