import React, { useEffect, useState } from "react";
import {
    getAllContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "./ContentItem";

const Reels = ({ filters }: any) => {
    const [contents, setContents] = useState<any>([]);

    useEffect(() => {
        getAllContents(
            {
                ...filters,
                subCategory: ["REELS"],
            },
            {
                skip: 0,
                limit: 4,
                orderBy: "createdAt",
                orderByDir: "DESC",
            }
        )
            .then((resp) => {
                setContents(() => {
                    return mapContentWithLength(resp.response.data);
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [filters]);

    return (
        <div className={"space-y-6"}>
            <div
                className={"text-2xl md:text-4xl text-primary-white font-bold"}
            >
                1 minute inspiration
            </div>
            <div className={"flex gap-6 overflow-auto md:grid grid-cols-4"}>
                {contents.map((content: any) => {
                    return <ContentItem key={content.id} content={content} />;
                })}
                {!contents.length && <div>No contents available.</div>}
            </div>
        </div>
    );
};

export default Reels;
