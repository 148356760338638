import { take } from "lodash";
import React, { useEffect, useState } from "react";
import {
    getNewContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "./ContentItem";

const NewOnStiry = ({ filters }: any) => {
    const [contents, setContents] = useState<any>([]);

    useEffect(() => {
        getNewContents(filters)
            .then((resp) => {
                setContents(() => {
                    return mapContentWithLength(
                        resp.response.data.filter(
                            (x: any) => x.subCategory !== "reels"
                        )
                    );
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [filters]);

    return (
        <>
            {contents.length ? (
                <div className={"space-y-3"}>
                    <div className={"text-2xl md:text-4xl text-primary-white font-bold"}>
                        New on Stiry+
                    </div>
                    <div className={"md:grid md:grid-cols-4 gap-6 flex overflow-auto"}>
                        {take(contents, 4).map((content: any) => {
                            return (
                                <div key={content.id}>
                                    <ContentItem content={content} />
                                </div>
                            );
                        })}

                        {!contents.length && <div>No contents available.</div>}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default NewOnStiry;
