import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../../shared/components/Footer";
import { getChannelById } from "../../shared/services/channel/channel.service";
import {
    getContentsByChannelId,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "../dashboard/ContentItem";

const Channel = () => {
    const { params } = useRouteMatch<{ id: string }>();

    const [channel, setChannel] = useState<any>(null);

    const [groupedContents, setGroupedContents] = useState<any>(null);

    useEffect(() => {
        if (params.id) {
            getChannelById(params.id)
                .then((resp) => {
                    setChannel(resp.response);
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });

            getContentsByChannelId(params.id)
                .then((resp) => {
                    setGroupedContents(() => {
                        return {
                            stiry_story: mapContentWithLength(
                                resp.response.stiry_story
                            ),
                            short: mapContentWithLength(resp.response.short),
                            video_podcast: mapContentWithLength(
                                resp.response.video_podcast
                            ),
                            reels: mapContentWithLength(resp.response.reels),
                            audio: mapContentWithLength(resp.response.audio),
                            image: mapContentWithLength(resp.response.image),
                        };
                    });
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [params]);

    return (
        <div className="md:absolute md:left-44 right-12 pt-10 flex flex-col min-h-screen-stiry px-6 md:px-0">
            {channel && groupedContents && (
                <div className={" pb-10"}>
                    <div
                        className={`text-2xl md:text-3xl text-primary-white font-bold mb-10`}
                    >
                        {channel.name}
                    </div>

                    <div className="space-y-20">
                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>
                                    Stiry+ Original Story
                                </div>
                                {groupedContents.stiry_story.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=video&subCategory=stiry_story`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.stiry_story.map(
                                    (content: any) => {
                                        return (
                                            <ContentItem
                                                key={content.id}
                                                content={content}
                                            />
                                        );
                                    }
                                )}
                                {!groupedContents.stiry_story.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>

                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>Short</div>
                                {groupedContents.short.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=video&subCategory=short`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.short.map((content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                })}
                                {!groupedContents.short.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>

                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>Video Podcast</div>
                                {groupedContents.video_podcast.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=video&subCategory=video_podcast`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.video_podcast.map(
                                    (content: any) => {
                                        return (
                                            <ContentItem
                                                key={content.id}
                                                content={content}
                                            />
                                        );
                                    }
                                )}
                                {!groupedContents.video_podcast.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>

                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>
                                    One-Minute Inspiration
                                </div>
                                {groupedContents.reels.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=video&subCategory=reels`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.reels.map((content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                })}
                                {!groupedContents.reels.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>

                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>Audios</div>
                                {groupedContents.audio.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=audio`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.audio.map((content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                })}
                                {!groupedContents.audio.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>
                        <div className={"space-y-6"}>
                            <div className="flex justify-between">
                                <div className={"text-xl"}>Images</div>
                                {groupedContents.image.length ? (
                                    <Link
                                        to={`/channel/${channel.id}/all?channel=&type=image`}
                                    >
                                        See all
                                    </Link>
                                ) : null}
                            </div>
                            <div className={"responsive-grid-wrapper"}>
                                {groupedContents.image.map((content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                })}
                                {!groupedContents.image.length && (
                                    <div>No contents available.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default Channel;
