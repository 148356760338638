import React, { createContext, useEffect, useReducer } from "react";

export interface IAuthContextState {
    user?: any;
    token?: any;
    loading?: boolean;
}
export interface IAuthProviderPayload {
    action: AuthActions;
    data: IAuthContextState;
}

export interface IAuthContextProps {
    authState: IAuthContextState;
    dispatch: (action: IAuthProviderPayload) => void;
}

const initialState = {
    user: null,
    token: null,
    loading: true,
};

export enum AuthActions {
    SETTOKEN,
    SETUSER,
    LOGOUT,
    SETLOADING,
}

export const AuthContext = createContext<IAuthContextProps>(
    {} as IAuthContextProps
);

export const AuthProvider = (props: any) => {
    const [state, dispatch] = useReducer(
        (currentState: IAuthContextState, payload: IAuthProviderPayload) => {
            switch (payload.action) {
                case AuthActions.SETTOKEN:
                    localStorage.setItem(
                        "token",
                        JSON.stringify(payload.data.token)
                    );
                    return {
                        ...currentState,
                        token: payload.data.token,
                    };

                case AuthActions.SETUSER:
                    localStorage.setItem(
                        "user",
                        JSON.stringify(payload.data.user)
                    );
                    return {
                        ...currentState,
                        user: payload.data.user,
                    };

                case AuthActions.LOGOUT:
                    localStorage.clear();
                    return {
                        ...currentState,
                        ...initialState,
                        loading: false,
                    };

                case AuthActions.SETLOADING:
                    return {
                        ...currentState,
                        loading: payload.data.loading,
                    };

                default:
                    throw new Error("Undefined action provided.");
            }
        },
        initialState
    );

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            dispatch({
                action: AuthActions.SETTOKEN,
                data: {
                    token: JSON.parse(token),
                },
            });

            const user = localStorage.getItem("user");
            if (user) {
                dispatch({
                    action: AuthActions.SETUSER,
                    data: {
                        user: JSON.parse(user),
                    },
                });
            }
        }

        dispatch({
            action: AuthActions.SETLOADING,
            data: {
                loading: false,
            },
        });
    }, [token]);

    return (
        <AuthContext.Provider value={{ authState: state, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    );
};
