import apolloClient from "../../../config/apolloClient";
import { tagsQuery } from "./tag.gql";

export const getTags = async () => {
    const { data, errors } = await apolloClient.query({
        query: tagsQuery,
    });

    if (errors) {
        return { errors };
    }

    return { response: data.tags };
};
