import gql from "graphql-tag";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        hasSubscription
        role {
            id
            name
        }
    }
`;

export const usersQuery = gql`
    query Users($pagingInput: PagingInput) {
        users(pagination: $pagingInput) {
            data {
                id
                firstName
                lastName
                role {
                    name
                }
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }
`;

export const userQuery = gql`
    query User($id: String!) {
        user(id: $id) {
            ...userFields
            channels {
                id
                name
            }
        }
    }

    ${userFieldsFragment}
`;
