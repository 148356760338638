import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
    changePasswordRequest,
    updatePasswordRequest,
} from "../../shared/services/auth/auth.service";
import { toast } from "react-toastify";
import { useHistory, useRouteMatch } from "react-router-dom";

const ChangePassword = () => {
    const { params } = useRouteMatch<{ token: string }>();

    const history = useHistory();

    useEffect(() => {
        if (!params.token) {
            history.push("/");
        }
    }, [params]);

    const onFinish = (values: any) => {
        updatePasswordRequest({ ...values, token: params.token }).then(
            (resp) => {
                toast.success(
                    "Password changed successfully. Please use new password to login to your account."
                );
                history.push("/");
            }
        );
    };

    return (
        <Form name="ChangePassword" onFinish={onFinish}>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please input your new password!",
                    },
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Change Password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ChangePassword;
