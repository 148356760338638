import React, { useEffect, useState } from "react";
import {
    getRecommendedContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import { orderBy, take } from "lodash";
import ContentItem from "./ContentItem";

const Recommended = ({ filters, showTitle = true }: any) => {
    const [contents, setContents] = useState<any>([]);

    useEffect(() => {
        getRecommendedContents(filters)
            .then((resp) => {
                setContents(() => {
                    const tempContents = take(
                        orderBy(
                            [
                                ...resp.response.stiry_story,
                                ...resp.response.short,
                                ...resp.response.video_podcast,
                                // ...resp.response.reels,
                                ...resp.response.audio,
                                ...resp.response.image,
                            ],
                            (x) => x.createdAt,
                            "desc"
                        ),
                        5
                    ).filter((x) => x.subCategory !== "reels");

                    return mapContentWithLength(tempContents);
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [filters]);

    return (
        <div className={"space-y-3"}>
            {showTitle ? (
                <div
                    className={
                        "text-2xl md:text-4xl text-primary-white font-bold"
                    }
                >
                    Recommended for you
                </div>
            ) : null}
            {!showTitle ? (
                <div className={"responsive-grid-wrapper"}>
                    {take(contents, 4).map((content: any) => {
                        return (
                            <div key={content.id}>
                                <ContentItem content={content} />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div
                    className={
                        "flex items-center gap-6 md:flex-row overflow-auto"
                    }
                >
                    {take(contents, 3).map((content: any, index: number) => {
                        return (
                            <div
                                key={content.id}
                                className={`${
                                    index === 0 ? "md:w-2/5" : "md:flex-1"
                                }`}
                                style={{
                                    minWidth: 250,
                                }}
                            >
                                <ContentItem
                                    content={content}
                                    large={index === 0}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
            {!contents.length && <div>No contents available.</div>}
        </div>
    );
};

export default Recommended;
