import gql from "graphql-tag";

export const tagsQuery = gql`
    query Tags {
        tags {
            id
            name
        }
    }
`;
