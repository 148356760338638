import React, { useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import Channels from "../../modules/dashboard/Channels";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ChannelsModal = ({ showModal = false, onClose }: any) => {
    const history = useHistory();

    useEffect(() => {
        const historyListener = history.listen((location, action) => {
            onClose();
        });

        return () => {
            historyListener();
        };
    }, []);

    return (
        <Modal
            visible={showModal}
            footer={null}
            closable
            onCancel={() => onClose()}
            width={1000}
            style={{}}
            bodyStyle={{
                background: "#121920",
                border: "1px solid #2A3036",
            }}
            closeIcon={<CloseOutlined className="text-primary-red" />}
            centered
        >
            <Channels showAll={true} isWidgetContainer={true} />
        </Modal>
    );
};

export default ChannelsModal;
