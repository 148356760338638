import React, { useContext, useEffect, useState } from "react";
import Channels from "../dashboard/Channels";
import { Form, Input, Button, Checkbox } from "antd";
import {
    createWordSearch,
    getAllContents,
    getSocialMedias,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import { debounce, uniqBy } from "lodash";
import ContentItem from "../dashboard/ContentItem";
import { AuthContext } from "../../shared/context/auth";
import styles from "./index.module.less";
import { SearchOutlined } from "@ant-design/icons";
import Recommended from "../dashboard/Recommended";
import Footer from "../../shared/components/Footer";
import { useHistory } from "react-router-dom";

const Search = () => {
    const [searchForm] = Form.useForm();

    const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

    const [contents, setContents] = useState<any>([]);

    const history = useHistory();

    const [filters, setFilters] = useState({
        search: "",
        socialMediaUsage: [],
        subCategory: [],
        mediaType: [],
    });

    const [paginationParams, setPaginationParams] = useState({
        skip: 0,
        limit: 50,
        pageNumber: 1,
    });

    const [hasNextPage, setHasNextPage] = useState(true);

    const [fetchTrigger, setFetchTrigger] = useState(0);

    const socialMedias = getSocialMedias();

    const { authState } = useContext(AuthContext);

    const [isUser, setIsUser] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        const historyListenerCallback = () => {
            const localSearchKey = new URLSearchParams(
                window.location.search
            ).get("tag");

            if (localSearchKey) {
                searchForm.setFieldsValue({ search: localSearchKey });
                setFilters((currentState) => {
                    return {
                        ...currentState,
                        search: localSearchKey,
                    };
                });
                setFetchTrigger((currentState) => {
                    return currentState + 1;
                });
            }
        };

        historyListenerCallback();

        const historyListener = history.listen(() => {
            historyListenerCallback();
        });

        return () => {
            historyListener();
        };
    }, []);

    useEffect(() => {
        setIsUser(authState?.user?.role?.name?.toLowerCase() === "user");
    }, [authState]);

    useEffect(() => {
        if (fetchTrigger > 0) {
            setIsDirty(false);
            if (filters.search === "") {
                setContents([]);
                return;
            }

            getAllContents(
                {
                    ...filters,
                    socialMediaUsage: filters?.socialMediaUsage?.length
                        ? filters.socialMediaUsage
                        : undefined,
                    subCategory: filters?.subCategory?.length
                        ? filters.subCategory
                        : undefined,
                    mediaType: filters?.mediaType?.length
                        ? filters.mediaType
                        : undefined,
                },
                {
                    skip: paginationParams.skip,
                    limit: paginationParams.limit,
                }
            )
                .then((resp) => {
                    setContents((currentState: any) => {
                        return uniqBy(
                            [...mapContentWithLength(resp.response.data || [])],
                            (x) => x.id
                        );
                    });

                    setIsDirty(true);
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [fetchTrigger]);

    return (
        <div className="md:absolute left-44 right-12 pt-10 flex flex-col min-h-screen-stiry px-6 md:px-0">
            <Form
                name={"search"}
                form={searchForm}
                onFinish={(values) => {
                    if (values.search) {
                        setFilters((currentState) => {
                            return {
                                ...currentState,
                                ...values,
                            };
                        });
                        setFetchTrigger((currentState) => {
                            return currentState + 1;
                        });

                        createWordSearch(values.search).then();
                    }
                }}
            >
                <div className="text-center text-6xl text-primary-white font-bold pt-10">
                    Search stiry...
                </div>
                {isAdvancedSearch ? (
                    <div className={"flex justify-center my-10"}>
                        <div
                            className={`space-y-4 border rounded border-secondary-blue-500  flex justify-center py-6 px-4 flex-col md:w-2/4`}
                        >
                            <Form.Item
                                name="search"
                                rules={[
                                    {
                                        required: true,
                                        message: "Search text is required.",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="What moves you?"
                                    className={styles.searchWrapper}
                                    prefix={<SearchOutlined />}
                                    autoFocus
                                    onChange={debounce((ev) => {
                                        searchForm.submit();
                                    }, 500)}
                                />
                            </Form.Item>

                            <div className={"flex gap-5 md:flex-row flex-col"}>
                                <div
                                    className={
                                        "flex-1 space-y-2 md:border-r border-secondary-blue-500"
                                    }
                                >
                                    <div className={"text-xs font-bold"}>
                                        Content Type
                                    </div>
                                    <Form.Item name="subCategory">
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="STIRY_STORY">
                                                    Stiry+ Original Story{" "}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="SHORT">
                                                    Short{" "}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="VIDEO_PODCAST">
                                                    Video Podcast{" "}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="REELS">
                                                    One-minute Inspiration{" "}
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <Form.Item name="mediaType">
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="AUDIO">
                                                    Audio{" "}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="IMAGE">
                                                    Image{" "}
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>

                                <div
                                    className={
                                        "flex-1 space-y-2 md:border-r border-secondary-blue-500 "
                                    }
                                >
                                    <div className={"text-xs font-bold"}>
                                        Length
                                    </div>
                                    <Form.Item name="length">
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value=">0<30">
                                                    less than 30 seconds
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">30<60">
                                                    30 sec - 1 min
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">60<120">
                                                    1 min - 2 min
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">120<300">
                                                    2 min - 5 min
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">300<600">
                                                    5 min - 10 min
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">600<1200">
                                                    10 min - 20 min
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value=">1200">
                                                    20 min +
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>

                                {!isUser && (
                                    <div className={"flex-1"}>
                                        <div className={"text-xs font-bold"}>
                                            Social Media Type
                                        </div>
                                        <Form.Item name="socialMediaUsage">
                                            <Checkbox.Group>
                                                {socialMedias.map(
                                                    (socialMedia) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    socialMedia
                                                                }
                                                            >
                                                                <Checkbox
                                                                    value={
                                                                        socialMedia
                                                                    }
                                                                >
                                                                    {
                                                                        socialMedia
                                                                    }
                                                                </Checkbox>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                )}
                            </div>

                            <div className={"flex justify-end gap-4"}>
                                <Button
                                    type="primary"
                                    ghost
                                    className={"rounded"}
                                    htmlType="button"
                                    onClick={() => {
                                        setIsAdvancedSearch(false);
                                    }}
                                >
                                    Simple Search
                                </Button>
                                <Button
                                    type="primary"
                                    className={"rounded"}
                                    htmlType="submit"
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"flex justify-center my-10 "}>
                        <div className="md:w-2/5">
                            <Form.Item name="search">
                                <Input
                                    type="text"
                                    className={styles.searchWrapper}
                                    placeholder="What moves you?"
                                    autoFocus
                                    prefix={<SearchOutlined />}
                                    onChange={debounce((ev) => {
                                        searchForm.submit();
                                    }, 500)}
                                />
                            </Form.Item>
                            <div className={"flex justify-center"}>
                                <Button
                                    type="link"
                                    className={"p-0 m-0"}
                                    onClick={() => {
                                        setIsAdvancedSearch(true);
                                    }}
                                >
                                    Advanced Search
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Form>
            <div className={"space-y-6"}>
                {contents.length ? (
                    <>
                        <div
                            className={`text-3xl text-primary-white font-bold`}
                        >
                            Results
                        </div>
                        <div className={"responsive-grid-wrapper"}>
                            {contents.map((content: any) => {
                                return (
                                    <ContentItem
                                        key={content.id}
                                        content={content}
                                    />
                                );
                            })}
                        </div>
                        <div>{!hasNextPage ? "End of list." : null}</div>
                    </>
                ) : null}

                {!contents.length && isDirty ? (
                    <div className="text-center">
                        <div className="text-4xl font-bold text-primary-white mb-4">
                            Sorry, we don't have that content yet.
                        </div>
                        <div className="mb-12">
                            Don't give up! Take a look at what others have found
                            helpful below:
                        </div>
                        <Recommended showTitle={false} />
                    </div>
                ) : null}

                <div className="py-10">
                    <Channels showAll={true} showTitle={false} />
                </div>
            </div>
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default Search;
