import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ClockIcon } from "./../../../assets/images/clock.svg";
import { take } from "lodash";
import ContentIcon from "../../shared/components/ContentIcon";
import ContentActions from "../content/ContentActions";
import styles from "./index.module.less";
import ReactFreezeframe from "react-freezeframe";

const ContentItem = ({ content }: any) => {
    const frameRef = useRef<any>();

    return (
        <div className="flex-shrink-0" style={{
            minWidth:250
        }}>
            <div className="flex items-center gap-2">
                <Link
                    className="text-primary-white pr-2 truncate"
                    to={`/content/${content.id}`}
                >
                    {content.title}
                </Link>
                <ClockIcon />
                <div>{content.duration}</div>
            </div>

            <div
                className={`relative group my-3  ${
                    content.coverOrientation === "portrait" &&
                    styles.portraitContentWrapper
                }`}
                onMouseEnter={() => {
                    frameRef.current?.start();
                }}
                onMouseLeave={() => {
                    frameRef.current?.stop();
                }}
            >
                {content.cover.url.includes(".gif") ? (
                    <div className="aspect-w-4 aspect-h-3 overflow-hidden">
                        <ReactFreezeframe
                            src={content.cover.url}
                            ref={frameRef}
                            className={"rounded-xl"}
                        />
                    </div>
                ) : (
                    <>
                        <div
                            className={`absolute w-2/4 rounded-xl z-10 bg-cover bg-center left-2/4 top-0 bottom-0 transform transition-all -translate-x-2/4`}
                            style={{
                                backgroundImage: `url(${content.cover.url})`,
                            }}
                        ></div>

                        <div className="aspect-w-4 aspect-h-3">
                            <div
                                className={`bg-center bg-cover rounded-xl ${styles.portraitCoverItem}`}
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                {content.coverOrientation === "portrait" ? (
                                    <div className="backdrop-filter rounded-xl backdrop-blur  w-full h-full bg-white/30 relative"></div>
                                ) : null}

                                <div className="absolute bottom-2 right-3 text-primary-white-light">
                                    <ContentIcon
                                        subCategory={content.subCategory}
                                        mediaType={content.media.type}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div
                    className={`absolute top-0 left-0 bottom-0 right-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all cursor-pointer z-20`}
                    style={{
                        background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%, #000000 100%)",
                    }}
                >
                    <div className="flex w-full h-full flex-col px-4 pb-4">
                        <Link
                            className="text-primary-white flex-grow relative"
                            to={`/content/${content.id}`}
                        >
                            <div className="line-clamp-4 absolute bottom-0 left-0 right-0 pb-1 text-primary-white">
                                {content.description}
                            </div>
                        </Link>
                        <div className="">
                            <ContentActions content={content} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                {take(content.tags, 3).map((tag: any) => {
                    return (
                        <Link
                            key={tag.id}
                            className="tag-button small truncate"
                            to={"/search?tag=" + tag.name}
                        >
                            {tag.name}
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default ContentItem;
