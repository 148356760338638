import apolloClient from "../../../config/apolloClient";
import { rolesQuery } from "./role.gql";

export const getRoles = async () => {
    const { data, errors } = await apolloClient.mutate({
        mutation: rolesQuery,
    });

    if (errors) {
        return { errors };
    }

    return { response: data.roles };
};
