import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./index.module.less";
import { ReactComponent as WatchlistIcon } from "./../../../assets/images/watchlist.svg";
import { ReactComponent as FavoriteIcon } from "./../../../assets/images/favorite.svg";
import { AuthActions, AuthContext } from "../context/auth";
import { Dropdown, Tooltip } from "antd";
import Channels from "../../modules/dashboard/Channels";

const LeftSidebar = () => {
    const { authState, dispatch } = useContext(AuthContext);

    const history = useHistory();

    const [pathName, setPathName] = useState("/");

    useEffect(() => {
        setPathName(history.location.pathname);

        const historyListener = history.listen((location, action) => {
            setPathName(history.location.pathname);
            window.scrollTo({
                top: 0,
            });
        });

        return () => {
            historyListener();
        };
    }, []);

    const rightMenuItem = (
        <div className="flex flex-col bg-secondary-dark rounded-lg py-2 px-4 gap-2 absolute left-14 w-40">
            <Link to={"/profile"}>
                {authState.user.firstName}&nbsp;
                {authState.user.lastName}
            </Link>
            <Link
                to={"#"}
                onClick={() => {
                    dispatch({
                        action: AuthActions.LOGOUT,
                        data: {},
                    });
                }}
            >
                Logout
            </Link>
        </div>
    );
    return (
        <div className="fixed shadow-2xl left-6 top-28 bottom-8 w-20 bg-secondary-dark rounded-lg hidden md:flex flex-col justify-between z-50">
            <div className="flex flex-col gap-1 mt-8">
                <Tooltip placement="right" title={"Home"}>
                    <Link
                        to="/"
                        className={`${styles.svgLink} ${
                            pathName === "/" && styles.active
                        }`}
                    >
                        <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.27607 0.28436C6.67765 -0.0947872 7.32873 -0.0947864 7.73031 0.284361L13.7031 5.92354C14.3509 6.53515 13.8921 7.58091 12.976 7.58091H12.2289V12.9993C12.2289 13.5516 11.7812 13.9993 11.2289 13.9993H8.83923V8.46706H5.44962V13.9993H3.06006C2.50777 13.9993 2.06006 13.5516 2.06006 12.9993V7.58091H1.03037C0.114245 7.58091 -0.344552 6.53515 0.303246 5.92354L6.27607 0.28436Z"
                            />
                        </svg>
                    </Link>
                </Tooltip>
                <Tooltip placement="right" title={"Watchlist"}>
                    <Link
                        to="/watchlist"
                        className={`${styles.svgLink} ${
                            pathName === "/watchlist" && styles.active
                        }`}
                    >
                        <WatchlistIcon />
                    </Link>
                </Tooltip>
                <Tooltip placement="right" title={"Favorites"}>
                    <Link
                        to="/favorites"
                        className={`${styles.svgLink} ${
                            pathName === "/favorites" && styles.active
                        }`}
                    >
                        <FavoriteIcon />
                    </Link>
                </Tooltip>
                <Dropdown
                    overlay={
                        <div
                            className="bg-secondary-dark rounded-lg p-4 absolute left-16"
                            style={{
                                width: 1000,
                            }}
                        >
                            <Channels showAll={true} isWidgetContainer={true} />
                        </div>
                    }
                    placement={"topRight"}
                >
                    <a
                        className={`ant-dropdown-link ${styles.svgLink}`}
                        onClick={(e) => e.preventDefault()}
                    >
                        <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="6" height="6" rx="1" />
                            <rect x="7" width="6" height="6" rx="1" />
                            <rect y="7" width="6" height="6" rx="1" />
                            <rect x="7" y="7" width="6" height="6" rx="1" />
                        </svg>
                    </a>
                </Dropdown>
            </div>
            <div className="flex flex-col items-center gap-5 mb-5">
                <Dropdown overlay={rightMenuItem} placement={"topRight"}>
                    <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="15" cy="15" r="15" fill="#1F272F" />
                            <circle
                                cx="15.2607"
                                cy="9.91304"
                                r="3.91304"
                                fill="black"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.00008 22.1958C9.12572 18.8488 11.8787 16.174 15.2565 16.174C18.6342 16.174 21.3872 18.8487 21.5129 22.1957C19.7017 23.3386 17.5563 24 15.2564 24C12.9565 24 10.8112 23.3387 9.00008 22.1958Z"
                                fill="black"
                            />
                        </svg>
                        <div className="text-primary-red pt-1">You</div>
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};

export default LeftSidebar;
