import React, { useContext, useEffect, useState } from "react";
import { Popconfirm } from "antd";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../shared/components/Footer";
import { AuthContext } from "../../shared/context/auth";
import {
    exchangeRefreshTokenRequest,
    getSubscription,
} from "../../shared/services/auth/auth.service";
import { cancelSubscription } from "../../shared/services/stripe/stripe.service";
import { getUserById } from "../../shared/services/user/user.service";

const Profile = () => {
    const { authState } = useContext(AuthContext);

    const history = useHistory();

    const [localUser, setLocalUser] = useState<any>();

    const [subscription, setSubscription] = useState<any>();

    useEffect(() => {
        getUserById(authState.user.id).then((resp) => {
            setLocalUser(resp.response);
        });

        getSubscription().then((resp) => {
            setSubscription(resp.response);
        });
    }, []);

    return (
        <div className="absolute md:left-44 right-12 pt-10  flex flex-col min-h-screen-stiry">
            <div className="flex justify-between">
                <div className={`text-3xl text-primary-white font-bold mb-4`}>
                    My Profile
                </div>
            </div>

            {localUser ? (
                <div className="space-y-2">
                    <div className="text-2xl">
                        {localUser.firstName} {localUser.lastName}
                    </div>
                    <div className="pb-4">{localUser.email}</div>

                    {authState.user.role.name !== "Content Manager" ? (
                        <>
                            <div>
                                Subscribed since{" "}
                                <span className="text-primary-white">
                                    {moment(subscription?.createdAt).format(
                                        "MMMM DD, yyyy"
                                    )}
                                </span>
                            </div>
                            <div>
                                <Popconfirm
                                    title="Are you sure to cancel your subscription?"
                                    onConfirm={() => {
                                        cancelSubscription().then((resp) => {
                                            toast.success(
                                                "Subscription cancelled successfully."
                                            );
                                            history.push("/subscription");
                                        });
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Link to={"#"} className="underline">
                                        Cancel My Subscription
                                    </Link>
                                </Popconfirm>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default Profile;
