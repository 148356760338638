import React from "react";
import { ReactComponent as AudioIcon } from "./../../../assets/images/audio.svg";
import { ReactComponent as ImageIcon } from "./../../../assets/images/image.svg";
import { ReactComponent as VideoPodcastIcon } from "./../../../assets/images/video_podcast.svg";
import { ReactComponent as VideoIcon } from "./../../../assets/images/video.svg";

const ContentIcon = ({
    subCategory,
    mediaType,
}: {
    subCategory: string;
    mediaType: string;
}) => {
    subCategory = (subCategory || "").toLowerCase();
    mediaType = (mediaType || "").toLowerCase();

    if (
        subCategory === "stiry_story" ||
        subCategory === "short" ||
        subCategory === "reels"
    ) {
        return <VideoIcon />;
    }

    if (subCategory === "video_podcast") {
        return <VideoPodcastIcon />;
    }

    if (mediaType === "audio") {
        return <AudioIcon />;
    }

    if (mediaType === "image") {
        return <ImageIcon />;
    }

    if (mediaType === "video") {
        return <VideoIcon />;
    }

    return null;
};

export default ContentIcon;
