import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { toast } from "react-toastify";
import { GraphQLError } from "graphql";
import {
    exchangeRefreshTokenRequest,
    getValidToken,
} from "../shared/services/auth/auth.service";

const REACT_APP_API_URL = process.env[`REACT_APP_API_URL`];
const REACT_APP_GRAPH_ENDPOINT = process.env[`REACT_APP_GRAPH_ENDPOINT`];

const httpLink = createHttpLink({
    uri: `${REACT_APP_API_URL}/${REACT_APP_GRAPH_ENDPOINT}`,
});

const authLink = setContext(async (_, { headers }) => {
    let token = await getValidToken(_.operationName);

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token.access_token}` : "",
        },
    };
});
/**
 * response: response from the server
 * graphQLErrors: GraphQLErrors
 * networkError: network Error
 */
const error = onError(({ graphQLErrors, networkError, response }) => {
    try {
        if (graphQLErrors && graphQLErrors[0] !== null) {
            const tempError: GraphQLErrorExtended[] = JSON.parse(
                JSON.stringify(graphQLErrors)
            );

            if (tempError[0].message === "Unathorized graphql request") {
                const token = JSON.parse(localStorage.getItem("token") || "{}");

                if (token && token.refresh_token) {
                    exchangeRefreshTokenRequest(token.refresh_token)
                        .then((resp) => {
                            localStorage.setItem(
                                "token",
                                JSON.stringify({
                                    ...token,
                                    ...resp.response,
                                })
                            );
                            window.location.reload();
                        })
                        .catch((err) => {
                            window.location.reload();
                            localStorage.clear();
                        });
                }
                return;
            }

            let errorMessage = tempError.map((x) => x.message).join(". ");
            if (errorMessage?.includes("E11000 duplicate key error")) {
                errorMessage =
                    "Email already used, please try different email to register your account.";
            }

            toast(errorMessage, {
                type: "error",
            });
        }

        if (networkError) console.log(`[Network error]: ${networkError}`);
    } catch (error) {}
});

const cache = new InMemoryCache({});

const apolloClient = new ApolloClient({
    link: error.concat(authLink.concat(httpLink)),
    cache: cache,
    defaultOptions: {
        mutate: { errorPolicy: "all" },
        query: { fetchPolicy: "no-cache" },
        watchQuery: { fetchPolicy: "no-cache" },
    },
});

export default apolloClient;

export interface GraphQLErrorExtended extends GraphQLError {
    details: string[];
    internalCode: number;
}
