import gql from "graphql-tag";
import { userFieldsFragment } from "../user/user.gql";

export const loginMutation = gql`
    mutation Login($input: LoginInput!) {
        Login(input: $input) {
            ...loginFields
        }
    }

    fragment loginFields on LoginResponse {
        access_token
        refresh_token
        user {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;

export const createUserMutation = gql`
    mutation CreateUser($createUserInput: CreateUserInput!) {
        createUser(createUserInput: $createUserInput) {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;

export const requestChangePasswordMutation = gql`
    mutation RequestChangePassword($input: RequestNewPasswordInput!) {
        RequestChangePassword(input: $input) {
            email
        }
    }
`;

export const requestNewPasswordMutation = gql`
    mutation RequestNewPassword($input: ChangePasswordInput!) {
        RequestNewPassword(input: $input) {
            email
        }
    }
`;

export const exchangeRefreshTokenMutation = gql`
    query ExchangeRefreshToken($input: ExchangeRefreshTokenInput!) {
        ExchangeRefreshToken(input: $input) {
            access_token
            refresh_token
        }
    }
`;

export const getSubscriptionQuery = gql`
    query getSubscription {
        getSubscription {
            id
            subscriptionId
            paymentMethodId
            customerId
            unsubscribeDate
            status
            createdAt
            updatedAt
        }
    }
`;
