import { createContext } from "react";
import apolloClient from "../../../config/apolloClient";
import { channelQuery, channelsQuery } from "./channel.gql";

export const ChannelListContext = createContext<any>({});

export const getChannels = async (orderByDir = "DESC") => {
    const { data, errors } = await apolloClient.query({
        query: channelsQuery,
        variables: {
            pagingInput: {
                limit: 0,
                orderBy: "createdAt",
                orderByDir,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.channels };
};

export const getChannelById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: channelQuery,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.channel };
};
