import gql from "graphql-tag";

export const rolesQuery = gql`
    query Roles {
        roles {
            id
            name
            description
            active
            createdAt
            updatedAt
        }
    }
`;
