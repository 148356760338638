import apolloClient from "../../../config/apolloClient";
import {
    contentQuery,
    contentsQuery as groupedContentsQuery,
    createDownloadContentMutation,
    createFavoriteMutation,
    createWatchlistMutation,
    createSeenContentMutation,
    favoritesQuery,
    featuredContentQuery,
    listContentQuery,
    newContentQuery,
    recommendedContentQuery,
    removeFavoriteMutation,
    removeWatchlistMutation,
    watchlistsQuery,
    relatedContentQuery,
    seenContentQuery,
    updateSeenContentMutation,
    createWordSearchMutation,
    downloadQuery,
} from "./content.gql";
import moment from "moment";

export const getContentsByChannelId = async (
    channel: string,
    search: string = ""
) => {
    const { data, errors } = await apolloClient.query({
        query: groupedContentsQuery,
        variables: {
            filters: {
                channel,
                search,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.groupedContent };
};

export const getAllContents = async (filters: any, pagination: any) => {
    const { data, errors } = await apolloClient.query({
        query: listContentQuery,
        variables: {
            filters,
            pagination,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.listContent };
};

export const getContentById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: contentQuery,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.content };
};

export const getFeaturedContent = async () => {
    const { data, errors } = await apolloClient.query({
        query: featuredContentQuery,
        variables: {
            pagination: {
                skip: 0,
                limit: 5,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.listFeaturedContent };
};

export const getRecommendedContents = async (filters: any) => {
    const { data, errors } = await apolloClient.query({
        query: recommendedContentQuery,
        variables: {
            filters,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.recommendedContent };
};

export const getNewContents = async (filters: any) => {
    const { data, errors } = await apolloClient.query({
        query: newContentQuery,
        variables: {
            filters,
            pagination: {
                skip: 0,
                limit: 10,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.newContent };
};

export const getRelatedContentsByChannelIds = async (content: string) => {
    const { data, errors } = await apolloClient.query({
        query: relatedContentQuery,
        variables: {
            filters: {
                content,
                limit: 5,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.relatedContent };
};

export const getFavoriteContents = async (filters: any, pagination: any) => {
    const { data, errors } = await apolloClient.query({
        query: favoritesQuery,
        variables: {
            pagination,
            filters,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.favorites };
};

export const saveFavoriteContent = async (content: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createFavoriteMutation,
        variables: {
            createFavoriteInput: {
                content,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createFavorite };
};

export const removeFavoriteContent = async (content: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeFavoriteMutation,
        variables: {
            deleteFavoriteInput: {
                content,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.removeFavorite };
};

export const saveContentDownload = async (user: string, content: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createDownloadContentMutation,
        variables: {
            createDownloadContentInput: {
                user,
                content,
                date: moment(new Date()),
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createDownloadContent };
};

export const getSeenContents = async (filters: any) => {
    const { data, errors } = await apolloClient.query({
        query: seenContentQuery,
        variables: {
            pagination: {
                skip: 0,
                limit: 100,
            },
            filters,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.seenContent };
};

export const getWatchlistContents = async (filters: any, pagination: any) => {
    const { data, errors } = await apolloClient.query({
        query: watchlistsQuery,
        variables: {
            pagination,
            filters,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.watchlist };
};

export const saveWatchlistContent = async (content: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createWatchlistMutation,
        variables: {
            createWatchlistInput: {
                content,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createWatchlist };
};

export const removeWatchlistContent = async (content: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeWatchlistMutation,
        variables: {
            deleteWatchListInput: {
                content,
            },
        },
    });

    if (errors) {
        throw errors;
    }
    return { response: data.removeWatchlist };
};

export const saveWatchDuration = async (
    user: string,
    content: string,
    watched: number
) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createSeenContentMutation,
        variables: {
            createSeenContentInput: {
                content,
                user,
                watched,
                lastWatched: moment(new Date()),
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createSeenContent };
};

export const updateWatchDuration = async (
    user: string,
    content: string,
    watched: number
) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: updateSeenContentMutation,
        variables: {
            updateSeenContentInput: {
                content,
                user,
                watched,
                lastWatched: moment(new Date()),
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createSeenContent };
};

export const createWordSearch = async (word: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createWordSearchMutation,
        variables: {
            createWordSearchInput: {
                word,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createWordSearch };
};

export const downloadVimeoVideo = async (video_id: string) => {
    const { data, errors } = await apolloClient.query({
        query: downloadQuery,
        variables: {
            params: {
                video_id,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.download };
};

export const mapContentWithLength = (contents: any[]) => {
    return contents.map((x: any) => {
        let duration =
            x.media.type === "image"
                ? ""
                : moment()
                      .startOf("day")
                      .add(x.length, "seconds")
                      .format("m:ss");

        return {
            ...x,
            duration,
        };
    });
};

export const getSocialMedias = () => {
    return [
        "Facebook Post",
        "Facebook Stories",
        "Instagram Post",
        "Instagram Reels",
        "Instagram Stories",
        "Pinterest Pin",
        "TikTok",
        "Vimeo",
        "Youtube",
    ];
};
