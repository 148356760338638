import gql from "graphql-tag";

export const contentFieldsFragment = gql`
    fragment contentFields on Content {
        id
        title
        subtitle
        featured
        featuredUntil
        channels {
            id
            name
        }
        tags {
            id
            name
        }
        cover {
            id
            url
            type
            createdAt
            updatedAt
        }
        coverFeaturedContent {
            id
            url
            type
            createdAt
            updatedAt
        }
        media {
            id
            url
            type
            size
            createdAt
            updatedAt
        }
        socialMediaUsage
        socialCopy
        length
        search
        alsoWatched
        favorite
        watchlist
        createdAt
        updatedAt
        isDownloadable
        description
    }
`;

export const contentFieldsMinimalFragment = gql`
    fragment contentFieldsMinimal on Content {
        id
        title
        subtitle
        length
        createdAt
        cover {
            url
        }
        media {
            type
            url
        }
        coverFeaturedContent {
            id
            url
            type
            createdAt
            updatedAt
        }
        tags {
            id
            name
        }
        favorite
        watchlist
        isDownloadable
        subCategory
        coverOrientation
        description
    }
`;

export const contentsQuery = gql`
    query GroupedContent($filters: GroupedContentFilters) {
        groupedContent(filters: $filters) {
            image {
                ...contentFieldsMinimal
            }
            audio {
                ...contentFieldsMinimal
            }
            video {
                ...contentFieldsMinimal
            }
            video_podcast {
                ...contentFieldsMinimal
            }
            short {
                ...contentFieldsMinimal
            }
            stiry_story {
                ...contentFieldsMinimal
            }
            reels {
                ...contentFieldsMinimal
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const listContentQuery = gql`
    query ListContent($pagination: PagingInput, $filters: ContentFilterInput) {
        listContent(pagination: $pagination, filter: $filters) {
            data {
                ...contentFieldsMinimal
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const wildCardSearchQuery = gql`
    query wildCardSearch(
        $pagination: PagingInput
        $filters: ContentFilterInput
    ) {
        wildCardSearch(pagination: $pagination, filter: $filters) {
            data {
                ...contentFieldsMinimal
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const featuredContentQuery = gql`
    query ListFeaturedContent($pagination: PagingInput) {
        listFeaturedContent(pagination: $pagination) {
            data {
                ...contentFieldsMinimal
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const recommendedContentQuery = gql`
    query RecommendedContent($filters: ContentFilterInput) {
        recommendedContent(filter: $filters) {
            video {
                ...contentFieldsMinimal
            }
            audio {
                ...contentFieldsMinimal
            }
            image {
                ...contentFieldsMinimal
            }
            video_podcast {
                ...contentFieldsMinimal
            }
            short {
                ...contentFieldsMinimal
            }
            stiry_story {
                ...contentFieldsMinimal
            }
            reels {
                ...contentFieldsMinimal
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const newContentQuery = gql`
    query NewContent($pagination: PagingInput, $filters: ContentFilterInput) {
        newContent(pagination: $pagination, filter: $filters) {
            data {
                ...contentFieldsMinimal
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const contentQuery = gql`
    query Content($id: String!) {
        content(id: $id) {
            ...contentFields
        }
    }

    ${contentFieldsFragment}
`;

export const createFavoriteMutation = gql`
    mutation CreateFavorite($createFavoriteInput: CreateFavoriteInput!) {
        createFavorite(createFavoriteInput: $createFavoriteInput) {
            id
        }
    }
`;

export const removeFavoriteMutation = gql`
    mutation RemoveFavorite($deleteFavoriteInput: DeleteFavoriteInput!) {
        removeFavorite(deleteFavoriteInput: $deleteFavoriteInput) {
            id
        }
    }
`;

export const favoritesQuery = gql`
    query favorites($pagination: PagingInput, $filters: FavoritesFilter) {
        favorites(pagination: $pagination, filters: $filters) {
            data {
                contents {
                    ...contentFieldsMinimal
                }
                channel {
                    id
                    name
                }
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }
    ${contentFieldsMinimalFragment}
`;

export const createDownloadContentMutation = gql`
    mutation createDownloadContent(
        $createDownloadContentInput: CreateDownloadContentInput!
    ) {
        createDownloadContent(
            createDownloadContentInput: $createDownloadContentInput
        ) {
            id
            date
        }
    }
`;

export const createWatchlistMutation = gql`
    mutation CreateWatchlist($createWatchlistInput: CreateWatchlistInput!) {
        createWatchlist(createWatchlistInput: $createWatchlistInput) {
            id
        }
    }
`;

export const removeWatchlistMutation = gql`
    mutation removeWatchlist($deleteWatchListInput: DeleteWatchListInput!) {
        removeWatchlist(deleteWatchListInput: $deleteWatchListInput) {
            id
        }
    }
`;

export const watchlistsQuery = gql`
    query watchlist($pagination: PagingInput, $filters: WatchListFilter) {
        watchlist(pagination: $pagination, filters: $filters) {
            data {
                contents {
                    ...contentFieldsMinimal
                }
                channel {
                    id
                    name
                }
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;
export const createSeenContentMutation = gql`
    mutation createSeenContent(
        $createSeenContentInput: CreateSeenContentInput!
    ) {
        createSeenContent(createSeenContentInput: $createSeenContentInput) {
            id
            watched
            lastWatched
        }
    }
`;

export const updateSeenContentMutation = gql`
    mutation updateSeenContent(
        $updateSeenContentInput: UpdateSeenContentInput!
    ) {
        updateSeenContent(updateSeenContentInput: $updateSeenContentInput) {
            id
            watched
            lastWatched
        }
    }
`;

export const relatedContentQuery = gql`
    query relatedContent($filters: RelatedContentFilters) {
        relatedContent(filters: $filters) {
            ...contentFieldsMinimal
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const seenContentQuery = gql`
    query seenContent($pagination: PagingInput, $filters: ContentFilterInput) {
        seenContent(pagination: $pagination, filter: $filters) {
            data {
                watched
                lastWatched
                viewed
                content {
                    ...contentFieldsMinimal
                }
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const createWordSearchMutation = gql`
    mutation createWordSearch($createWordSearchInput: CreateWordSearchInput!) {
        createWordSearch(createWordSearchInput: $createWordSearchInput) {
            id
        }
    }
`;

export const downloadQuery = gql`
    query download($params: Download!) {
        download(params: $params) {
            link
        }
    }
`;
