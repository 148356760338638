import React, { useContext, useEffect, useState } from "react";
import { VideoJsPlayerOptions } from "video.js";
import VideoJS from "../../shared/components/VideoJS";
import { AuthContext } from "../../shared/context/auth";
import { updateWatchDuration } from "../../shared/services/content/content.service";
import ContentActions from "./ContentActions";
import Player from "@vimeo/player";
import { updateLastWatched } from "../../shared/helpers/playback";
import { useRouteMatch } from "react-router-dom";

const Watch = ({ content }: any) => {
    const { authState } = useContext(AuthContext);

    const { params } = useRouteMatch<{ id: string }>();

    const videoJsOptions: VideoJsPlayerOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: window.innerWidth < 800,
        loop: false,
        controlBar: {
            pictureInPictureToggle: false,
        },
        sources: [
            {
                src: content.media.url,
            },
        ],
        poster: content.cover.url,
        height: 600,
    };

    const [closedAt, setClosedAt] = useState(0);

    useEffect(() => {
        if (closedAt) {
            updateWatchDuration(authState.user.id, content.id, Number(closedAt))
                .then((resp) => {
                    console.log("watch log saved.");
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
            return () => {};
        }
    }, [closedAt]);

    useEffect(() => {
        let vimeoPlayer: any;
        if (content.media.url.includes("vimeo")) {
            const lastWatched = JSON.parse(
                localStorage.getItem("LastWatched") || "[]"
            );

            const seconds =
                lastWatched.find((x: any) => x.id === params.id)?.watched || 0;

            vimeoPlayer = new Player("vimeoPlayer", {
                autoplay: true,
                pip: 1,
            });

            if (seconds) {
                vimeoPlayer.setCurrentTime(seconds);
            }

            vimeoPlayer.on("play", () => {
                console.log("played the player 2.0 video!");
            });

            vimeoPlayer.on("progress", (ev: any) => {
                const percent = Number((ev.percent * 100).toFixed());

                setClosedAt(percent);

                updateLastWatched(params.id, ev.seconds);
            });

            vimeoPlayer.getVideoUrl().then((title: string) => {
                console.log("title:", title);
            });
            console.log(
                "🚀 ~ file: Watch.tsx ~ line 81 ~ vimeoPlayer.getVideoTitle ~ vimeoPlayer",
                vimeoPlayer
            );
        }

        return () => {};
    }, [content]);

    return (
        <div>
            {content && (
                <div className={"space-y-4"}>
                    {content.media.type.toLowerCase() === "image" && (
                        <div className="flex justify-center">
                            <img
                                src={content.media.url}
                                alt="Preview"
                                className={"max-w-full"}
                            />
                        </div>
                    )}
                    {(content.media.type.toLowerCase() === "video" ||
                        content.media.type.toLowerCase() === "audio") && (
                        <div>
                            {content.media.url.includes("vimeo") ? (
                                <div
                                    data-vimeo-url={content.media.url}
                                    id="vimeoPlayer"
                                    className="aspect-w-16 aspect-h-9"
                                    data-vimeo-autoplay={true}
                                ></div>
                            ) : (
                                <VideoJS
                                    options={videoJsOptions}
                                    isAudio={
                                        content.media.type.toLowerCase() ===
                                        "audio"
                                    }
                                    setClosedAt={setClosedAt}
                                />
                            )}
                        </div>
                    )}

                    <div className="space-y-2">
                        <div
                            className={
                                "md:flex justify-between space-y-2 md:space-y-0"
                            }
                        >
                            <div
                                className={`font-bold text-2xl md:text-4xl text-primary-white`}
                            >
                                {content.title}
                            </div>

                            <ContentActions content={content} isWatch={true} />
                        </div>
                        <div className={` `}>{content.subtitle}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Watch;
