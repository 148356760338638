import { uniqBy } from "lodash";

export const updateLastWatched = (id: string, watched: number | string) => {
    const lastWatched = JSON.parse(localStorage.getItem("LastWatched") || "[]");

    const thisWatched = lastWatched.find((x: any) => x.id === id) || {
        id: id,
        watched: 0,
    };

    thisWatched.watched = watched;

    lastWatched.push(thisWatched);

    localStorage.setItem(
        "LastWatched",
        JSON.stringify(uniqBy(lastWatched, (x: any) => x.id))
    );
};
