import apolloClient from "../../../config/apolloClient";
import {
    createSubscriptionMutation,
    getStripeProductByIdQuery,
    getStripeProductsQuery,
    removeSubscriptionMutation,
} from "./stripe.gql";

export const getStripeProducts = async () => {
    const { data, errors } = await apolloClient.query({
        query: getStripeProductsQuery,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.getStripeProducts };
};

export const getStripeProductById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: getStripeProductByIdQuery,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.getStripeProductById };
};

export const createSubscription = async (payload: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createSubscriptionMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createSubscription };
};

export const cancelSubscription = async () => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeSubscriptionMutation,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.removeSubscription };
};

export const cancelSubscriptionAtTheEndOfTheBillingCycle = async () => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeSubscriptionMutation,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.cancelSubscriptionAtTheEndOfTheBillingCycle };
};
