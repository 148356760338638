import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "antd";
import { createSubscription } from "../services/stripe/stripe.service";

const CheckoutModal = ({ showModal = false, onClose, onSuccess }: any) => {
    const history = useHistory();

    const elements = useElements();

    const stripe = useStripe();

    const [errorMessage, setErrorMessage] = useState("");

    const [isCardReady, setIsCardReady] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const historyListener = history.listen((location, action) => {
            onClose();
        });

        return () => {
            historyListener();
        };
    }, []);

    return (
        <Modal
            visible={showModal}
            closable
            footer={null}
            onCancel={() => onClose()}
            width={500}
            style={{}}
            bodyStyle={{
                background: "#121920",
                border: "1px solid #2A3036",
            }}
            closeIcon={<CloseOutlined className="text-primary-red" />}
        >
            <div className="space-y-4">
                <div className="text-3xl text-primary-white mb-4">Checkout</div>

                <CardElement
                    options={{
                        style: {
                            base: {
                                iconColor: "#fff",
                                color: "#fff",
                                "::placeholder": {
                                    color: "#fff",
                                    iconColor: "#fff",
                                },
                                fontSize: "18px",
                            },
                        },
                    }}
                    onChange={(ev) => {
                        if (ev.error) {
                            setErrorMessage(ev.error.message);
                        } else {
                            setErrorMessage("");
                        }

                        setIsCardReady(ev.complete);
                    }}
                    className="border py-1 px-2 border-primary-border"
                />
                {errorMessage ? (
                    <div className="text-primary-red-hover">{errorMessage}</div>
                ) : null}

                <div>
                    <Button
                        disabled={!isCardReady}
                        loading={isLoading}
                        type={"primary"}
                        size="large"
                        onClick={() => {
                            setIsLoading(true);
                            const card = elements?.getElement(CardElement);
                            if (card) {
                                stripe
                                    ?.createPaymentMethod({
                                        type: "card",
                                        card: card,
                                    })
                                    .then((resp) => {
                                        if (resp.paymentMethod) {
                                            const paymentMethodId =
                                                resp.paymentMethod.id;

                                            onSuccess(paymentMethodId);
                                        }

                                        if (resp.error) {
                                            setErrorMessage(
                                                "Failed to process the card. Please try again in a while."
                                            );
                                            setIsLoading(false);
                                        }
                                    });
                            }
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CheckoutModal;
