import React, { useEffect } from "react";
import {
    IRegisterPayload,
    registerRequest,
} from "../../shared/services/auth/auth.service";
import { Form, Input, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { getRoles } from "../../shared/services/role/role.service";
import { toast } from "react-toastify";

const Register = () => {
    const history = useHistory();

    const onFinish = (values: IRegisterPayload) => {
        getRoles().then((resp) => {
            const userRole: any = resp.response.find(
                (x: any) => x.name.toLowerCase() === "user"
            );

            registerRequest({ ...values, role: userRole?.id }).then((resp) => {
                toast.success(
                    "Registration completed successfully. Login to continue."
                );
                history.push("/");
            });
        });
    };

    return (
        <Form name="Register" onFinish={onFinish} layout={"vertical"}>
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    {
                        required: true,
                        message: "Please input your First Name!",
                    },
                ]}
            >
                <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    { required: true, message: "Please input your Last Name!" },
                ]}
            >
                <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: "Please input your Email!" },
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    { required: true, message: "Please input your Password!" },
                ]}
            >
                <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Register
                </Button>
                <div className={"text-center mt-2"}>
                    Already have an account.
                    <Link to={"/auth/"}>Login Now.</Link>
                </div>
            </Form.Item>
        </Form>
    );
};

export default Register;
