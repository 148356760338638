import { take } from "lodash";
import React, { useEffect, useState } from "react";
import {
    getRelatedContentsByChannelIds as getRelatedContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "../dashboard/ContentItem";

const Related = ({ content }: any) => {
    const [contents, setContents] = useState<any>([]);

    useEffect(() => {
        if (content) {
            getRelatedContents(content.id)
                .then((resp) => {
                    setContents(() => {
                        return mapContentWithLength(
                            take(resp.response, 4).filter(
                                (x: any) => x.id !== content.id
                            )
                        );
                    });
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [content]);

    return (
        <div className={"space-y-3"}>
            <div className={"text-xl"}>Related</div>

            <div className={"md:grid md:grid-cols-4 gap-6 flex overflow-auto"}>
                {contents.map((content: any) => {
                    return (
                        <div key={content.id}>
                            <ContentItem
                                content={{
                                    ...content,
                                    coverOrientation: content.coverOrientation,
                                }}
                            />
                        </div>
                    );
                })}

                {!contents.length && <div>No contents available.</div>}
            </div>
        </div>
    );
};

export default Related;
