import React, { useEffect, useState } from "react";
import { debounce, flatten, groupBy, uniqBy } from "lodash";
import {
    getWatchlistContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "../dashboard/ContentItem";
import { Input, Select } from "antd";
import Footer from "../../shared/components/Footer";
const { Option } = Select;

const Watchlist = () => {
    const [contents, setContents] = useState<any>([]);

    const [contentsByChannel, setContentsByChannel] = useState<any>([]);

    const [contentsByMediaType, setContentsByMediaType] = useState<any>({});

    const [paginationParams, setPaginationParams] = useState({
        skip: 0,
        limit: 30,
        pageNumber: 1,
    });

    const [hasNextPage, setHasNextPage] = useState(true);

    const [fetchTrigger, setFetchTrigger] = useState(0);

    const [filters, setFilters] = useState({
        search: "",
        orderByChannel: false,
        orderByDate: true,
    });

    useEffect(() => {
        const scrollCallback = (ev: any) => {
            if (!filters.orderByChannel && !filters.orderByDate) {
                return;
            }

            if (
                window.innerHeight + window.scrollY >=
                    document.body.scrollHeight &&
                hasNextPage
            ) {
                setPaginationParams((currentState) => {
                    return {
                        ...currentState,
                        skip: currentState.pageNumber * currentState.limit,
                    };
                });

                setFetchTrigger((currentState) => {
                    return currentState + 1;
                });
            }
        };

        window.addEventListener("scroll", scrollCallback);

        return () => {
            window.removeEventListener("scroll", scrollCallback, false);
        };
    }, [hasNextPage]);

    useEffect(() => {
        getWatchlistContents(filters, {
            skip: paginationParams.skip,
            limit: paginationParams.limit,
        })
            .then((resp) => {
                if (filters.orderByDate) {
                    setContents((currentState: any) => {
                        return uniqBy(
                            [
                                ...currentState,
                                ...mapContentWithLength(
                                    flatten(
                                        resp.response.data.map(
                                            (x: any) => x.contents
                                        )
                                    )
                                ),
                            ],
                            (x) => x.id
                        );
                    });
                }

                if (filters.orderByChannel) {
                    setContentsByChannel((currentState: any) => {
                        return [...currentState, ...resp.response.data];
                    });
                }

                if (!filters.orderByChannel && !filters.orderByDate) {
                    setContentsByMediaType((currentState: any) => {
                        const contents: any = groupBy(
                            resp.response.data[0]?.contents,
                            (x) => x.media.type
                        );

                        contents.video = groupBy(
                            contents.video,
                            (x) => x.subCategory
                        );

                        return {
                            ...currentState,
                            ...contents,
                        };
                    });
                }

                setPaginationParams((currentState) => {
                    return {
                        ...currentState,
                        pageNumber: currentState.pageNumber + 1,
                    };
                });

                setHasNextPage(resp.response.pagingMeta.hasNextPage);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [fetchTrigger]);

    useEffect(() => {
        setContents([]);
        setPaginationParams(() => {
            return {
                skip: 0,
                limit: 30,
                pageNumber: 1,
            };
        });

        setHasNextPage(true);

        setFetchTrigger((currentState) => {
            return currentState + 1;
        });
    }, [filters]);

    return (
        <div className="md:absolute px-6 md:px-0 left-44  right-12 md:pt-10 flex flex-col min-h-screen-stiry">
            <div className="md:flex justify-between mb-4 md:mb-0">
                <div
                    className={`text-2xl md:text-3xl text-primary-white font-bold md:mb-10 mb-4`}
                >
                    Watchlists
                </div>
                <div className={"md:flex justify-end gap-5 space-y-4"}>
                    <div>
                        <Input
                            type="text"
                            placeholder={"Search..."}
                            className={"searchWrapperStiry"}
                            onChange={debounce((ev) => {
                                setContents([]);
                                setContentsByChannel([]);
                                setContentsByMediaType({});
                                setFilters((currentState) => {
                                    return {
                                        ...currentState,
                                        search: (ev.target as any).value,
                                    };
                                });
                            }, 500)}
                        />
                    </div>

                    <div>
                        <Select
                            defaultValue={"date"}
                            className={"dropdownWrapperStiry"}
                            onChange={(value: string) => {
                                setFilters((currentState) => {
                                    return {
                                        ...currentState,
                                        orderByChannel: value === "channel",
                                        orderByDate: value === "date",
                                    };
                                });
                            }}
                        >
                            <Option disabled value={"-1"}>
                                Order By
                            </Option>
                            <Option value="date">Date</Option>
                            <Option value="channel">Channel</Option>
                            <Option value="mediaType">Content Type</Option>
                        </Select>
                    </div>
                </div>
            </div>

            {filters.orderByDate && (
                <div className={"responsive-grid-wrapper"}>
                    {contents.map((content: any) => {
                        return (
                            <ContentItem key={content.id} content={content} />
                        );
                    })}

                    {!contents.length && (
                        <div>No watchlist contents available.</div>
                    )}
                </div>
            )}

            {filters.orderByChannel && (
                <div className={"space-y-10"}>
                    {contentsByChannel.map((group: any) => {
                        return (
                            <div>
                                <div className={"text-2xl"}>
                                    {group.channel?.name}
                                </div>
                                <div className={"responsive-grid-wrapper"}>
                                    {group.contents.map((content: any) => {
                                        return (
                                            <ContentItem
                                                key={content.id}
                                                content={content}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {!filters.orderByChannel && !filters.orderByDate && (
                <div className={"space-y-10"}>
                    <div>
                        <div className={"text-2xl"}>Stiry+ Original Story</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.video?.stiry_story?.map(
                                (content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                }
                            )}
                            {!contentsByMediaType?.video?.stiry_story
                                ?.length && (
                                <div>
                                    No Stiry+ Original contents available.
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className={"text-2xl"}>Short</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.video?.short?.map(
                                (content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                }
                            )}
                            {!contentsByMediaType?.video?.short?.length && (
                                <div>No shorts available.</div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className={"text-2xl"}>Video Podcast</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.video?.video_podcast?.map(
                                (content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                }
                            )}
                            {!contentsByMediaType?.video?.video_podcast
                                ?.length && (
                                <div>No video podcasts available.</div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className={"text-2xl"}>One-minute Inspiration</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.video?.reels?.map(
                                (content: any) => {
                                    return (
                                        <ContentItem
                                            key={content.id}
                                            content={content}
                                        />
                                    );
                                }
                            )}
                            {!contentsByMediaType?.video?.reels?.length && (
                                <div>No one-minute inspiration available.</div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className={"text-2xl"}>Audio</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.audio?.map((content: any) => {
                                return (
                                    <ContentItem
                                        key={content.id}
                                        content={content}
                                    />
                                );
                            })}
                            {!contentsByMediaType?.audio?.length && (
                                <div>No audio contents available.</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className={"text-2xl"}>Image</div>
                        <div className={"responsive-grid-wrapper"}>
                            {contentsByMediaType?.image?.map((content: any) => {
                                return (
                                    <ContentItem
                                        key={content.id}
                                        content={content}
                                    />
                                );
                            })}
                            {!contentsByMediaType?.image?.length && (
                                <div>No image contents available.</div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!hasNextPage ? <div className="py-10"> End of list.</div> : null}
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default Watchlist;
